.input {
    display: inline-flex;
	@include font-size(button);
    @include font-weight(button);
	padding: (($button-form--height - get-line-height(input) - ($button-form--border-width * 2)) / 2) ($button--padding-h - $button-form--border-width);
	height: $button-form--height;
    text-decoration: none !important;
	text-transform: $button--transform;
	color: #ECEDED;
    background-color: #25282C;
	border-style: none;
	border-color: color-border(button-light);
    border-radius: $button--radius;
    cursor: pointer;
    letter-spacing: inherit;
    white-space: nowrap;
	transition: background .15s ease;

    &:active {
        outline: 0;
    }

    &:focus {
        outline: 0;
    }

	&:hover {
		background-color: #2a2d32;
	}
}

.inputLabel {
    text-align: left;
}

.input:disabled {
    opacity: 0.5;
}
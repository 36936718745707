.blog-post {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .blog-header-image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    .blog-header {
        text-align: center;
    }

    .blog-area {
        background-color: #25282C;
        padding-bottom: 100px;
        text-align: left;
        width: 100%;

        img {
            padding-left: 70px;
            padding-right: 70px;
            padding-bottom: 30px;
        }
    }

    p, ul, h3 {
        margin-left: 30px;
        padding-right: 30px;
        color:white;
    
        &.main {
            font-weight: bold;
        }

    }

    a {
        color: #6163ff;

        &.white {
            color: white;
        }
    }

    a:hover {
        text-decoration: underline;
    }
}
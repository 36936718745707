.payment-holder{
    padding-top: 20px;
    max-width: 400px;
    width: 350px;
    
    #card-element {
        padding-left: 10px;
        background-color: #25282C;
        border-radius: 2px;
    }

    .CardField.CardField--ltr {
        left: 5px;
    }

    .payment-form {
        color: #ECEDED;
    }

    .small-text {
        font-size: small;
        text-align: center;
    }

    .medium-text {
        font-size: medium;
        text-align: center;
    }

    .full-width {
        width: 100%;
    }

    .bottom-padding {
        padding-bottom: 10px;
    }

    .bottom-margin {
        margin-bottom: 10px;
    }

    .little-padding {
        padding: 15px;
    }

    .error {
        color:red;
    }

    .margin-top {
        margin-top: 10px;
    }
}


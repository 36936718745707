.login-wrapper {
    display: flex;
    justify-content: center;
}

.login-box {
    display: flex;
    background-color: #25282C;
    width: 500px;
    padding: 30px;
    flex-direction: column;
    border-radius: 2px;
}

.login-header {
    color: #5658DD;
    text-align: center;
}

.login-label {
    flex-basis: 100%;
}

.validation-label {
    flex: 1 1 100%;
    text-align: center;
    color:red;
}

.message-label {
    flex: 1 1 100%;
    text-align: center;
}

.login-button {
    flex: 1;
    flex-basis: 100%;
    margin-top: 20px
}

.login-input {
    background-color: #ECEDED;
    color: #151719;
    margin-bottom: 10px;
    padding: 10px;

    &:hover {
        background-color: #ECEDED;
    }
}
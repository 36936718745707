.upgrade-card {
    text-align: center;
    padding: 20px;
    color: white;

    transition: all .5s;

    .center-text {
        text-align: center;
    }
    
    .clean-list {
        list-style: none;
        padding: 0px;

        li {
            padding: 0px;
            margin: 0px;
        }
    }
    
    .full-width {
        width: 150px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
    }

    .upgrade-body {
        background-color: #25282C;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 250px;
        padding-top: 20px;
    }

    .page-count {
        font-size: 50px;
    }

    .plan-name {
        font-size: x-large;
        padding-top: 10px;
    }

    .plan-price {
        font-size: 50px;
        padding-top: 30px;
    }

    div.small-padding {
        padding: 0px;
    }

    .feature-list {
        padding-top: 20px;
        font-size: medium;
    }

    .small-description {
        font-size: medium;
        padding-top: 10px;
    }

    .smallest-description {
        font-size: small;
        padding-top: 10px;
    }

    .upgrade-header {
        
        height: 150px;
        width: 250px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &.card-1 {
            background-color: #0092D6;
        }

        &.card-2 {
            background-color: #4883D4;
        }

        &.card-3 {
            background-color: #6F72C9;
        }

        &.card-4 {
            background-color: #8D5FB6;
        }

        &.card-5 {
            background-color: #A4489C;
        }

        &.card-6 {
            background-color: #B1307B;
        }

        &.card-7 {
            background-color: #933666;
        }
    }

    .button-current {
        background-color: transparent;
        border-width: 2px;
        border-color: #6163FF;
        cursor: default;
    }
}
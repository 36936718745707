.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.dashboard-pane {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
    min-width: 300px;
    max-width: 1000px;
    padding: 10px;

    .dashboard-header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    h3 {
        display: flex;
        text-align: left;
        flex: 1 0 50%;
    }

    button.header {
        flex: 0 0;
        display: flex;
    }
}

.dashboard-area {
    width: 100%;
    height: 70vh;
    background-color: #25282C;
    border-radius: 2px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
}

.dashboard-with-bottom {
    align-content: space-between;
    overflow: hidden;
}

.dashboard-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;
    flex-basis: 100%;
    flex: 1;
    background-color: #373c42;
    align-items: center;
    border-radius: 2px;
    flex-basis: 100%;
    max-width: calc(100% - 20px);
}

.dashboard-line-1 {
    display: flex;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;

    .dashboard-row-status {
        flex: 0;
        flex-basis: 15px;
        margin-right: 10px;
        padding-top: 8px;
    }

    .dashboard-row-website {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


.dashboard-row-action {
    display: flex;
    flex: 1;

    @media screen and (max-width: 750px) {
        justify-content: center;
    }

    @media screen and (min-width: 750px) {
        margin-left: auto;
        flex-direction: row-reverse;
    }
    

    button {
        display: flex;
        height: auto;
        width: 80px;
        min-width: 80px;
        margin: 10px;
    }
}

.footer-button-group {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-wrap: wrap;
    margin-top: auto;

    .button {
        margin: 10px;
        display: flex;
        flex: 1;
    }
}

.dashboard-form {
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .form-item {
        flex: 1 0 50%;
        min-width: 200px;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .form-item-large {
        flex: 1 0 100%;
    }

    .label {
        flex: 1 0 100%;
    }

    .input {
        padding: 10px;
        width: 100px;
        background-color: #ECEDED;
        color: #151719;
        flex: 1 0  100%;
    }
}

li.padding {
    padding-left: 32px;
    padding-right: 32px;
}

.clickable {
    cursor: pointer;

    &:hover{
        color: #ECEDED;
    }
}

.header-nav{
    a.active {
        color: #ECEDED;
        cursor: default;
    }
}

.separator {
    width: 100%;
    height: 15px;
    margin: 20px 0 20px 0;
    border-bottom: 1px solid white;
    text-align: center;
}

.separator-text {
    background-color: #151719;
    padding: 0 20px;
}

.bold {
    font-weight: bold;
}

.top-padding {
    padding-top: 10px
}

.bottom-padding {
    padding-bottom: 10px
}

.hidden{
    visibility: hidden;
}

.text-center {
    text-align: center;
}

.flex-form {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    width: 100%;

    .form-item {
        flex: 1 0 50%;
        min-width: 200px;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    .form-item-large {
        flex: 1 0 100%;
    }

    .label {
        flex: 1 0 100%;
    }

    .input {
        padding: 10px;
        width: 100px;
        flex: 1 0  100%;
    }
}